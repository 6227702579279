<script setup lang="ts">

import {useResultasticInfoPage} from "~/composables/useResultasticInfoPage";

const {resultasticStore} = useResultasticInfoPage();

await resultasticStore.loadFeatures();

</script>

<template>
    <nuxt-page/>
</template>
